import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

Vue.use(Router)

const routes = [
  {
    path: '/',
    component: () => import('@/views/home/Index'),
    children: [
      // Something
    ],
  },
  {
    path: '/login',
    component: () => import('@/views/login/Index'),
    children: [
      {
        name: 'LoginView',
        path: '',
        component: () => import('@/views/login/Login'),
      },
      {
        name: 'RestorePasswordView',
        path: 'restorepassword',
        component: () => import('@/views/login/RestorePassword'),
      },
    ],
  },
  {
    path: '/dashboard',
    component: () => import('@/views/dashboard/Index'),
    meta: {
      requiresAuth: true,
    },
    children: [
      // Dashboard
      // {
      //   name: 'Admin',
      //   path: 'admin',
      //   component: () => import('@/views/dashboard/Dashboard'),
      // },
      // Pages
      {
        name: 'User Profile',
        path: 'user',
        component: () => import('@/views/dashboard/pages/UserProfile'),
      },
      // {
      //   name: 'Notifications',
      //   path: 'alerts',
      //   component: () => import('@/views/dashboard/component/Notifications'),
      // },
      {
        name: 'Icons',
        path: 'components/icons',
        component: () => import('@/views/dashboard/component/Icons'),
      },
      {
        name: 'Typography',
        path: 'components/typography',
        component: () => import('@/views/dashboard/component/Typography'),
      },
      // Tables
      {
        name: 'Regular Tables',
        path: 'tables/regular-tables',
        component: () => import('@/views/dashboard/tables/RegularTables'),
      },
      // Maps
      {
        name: 'Mapa',
        path: 'map',
        component: () => import('@/views/dashboard/maps/GoogleMaps'),
      },
      {
        name: 'Mis Reportes',
        path: 'reportes',
        component: () => import('@/views/dashboard/Reports'),
      },
      {
        name: 'Mis Alertas',
        path: 'alertas',
        component: () => import('@/views/dashboard/Alerts'),
      },
      // {
      //   name: 'Open Street Maps',
      //   path: 'maps/osm-maps',
      //   component: () => import('@/views/dashboard/maps/OpenStreetMaps'),
      // },
      // Upgrade
      // {
      //   name: 'Upgrade',
      //   path: 'upgrade',
      //   component: () => import('@/views/dashboard/Upgrade'),
      // },
    ],
  },
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Ensure we checked auth before each page load.
    Promise.all([store.dispatch('checkAuth')]).then(next)
  } else {
    next()
  }
})

export default router
