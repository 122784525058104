import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import JwtService from '@/common/jwt.service'

const API_URL = process.env.VUE_APP_HOST

const ApiService = {
  init () {
    Vue.use(VueAxios, axios)
    Vue.axios.defaults.baseURL = API_URL
    Vue.axios.defaults.xsrfCookieName  = 'csrftoken'
    Vue.axios.defaults.xloadingerName  = 'X-CSRFToken'
    Vue.axios.defaults.withCredentials = true
  },

  setHeader () {
    Vue.axios.defaults.headers.common.Authorization = `JWT ${JwtService.getToken()}`
  },

  //// seems insecure
  //setCSRFToken ()  {
  //  return Vue.axios.get('csrf/').then((response) => {
  //      if (response.status === 200) {
  //        Vue.axios.defaults.headers.common['X-CSRFToken'] = response.data.token;
  //      } else {
  //        throw new Error(`[RIVERINE]:ApiService:setCSRFToken response code ` + response.status )
  //      }
  //    }).catch( error => {
  //        throw new Error(`[RIVERINE]:ApiService:setCSRFToken ${error}`)
  //    })
  //},

  simpleGet (resource) {
    return Vue.axios.get(`${resource}`).catch(error => {
      console.log(error)
      throw new Error(`[RIVERINE] ApiService ${error}`)
    })
  },

  get (resource, slug = '') {
    return Vue.axios.get(`${resource}/${slug}`).catch(error => {
      console.log(error)
      throw new Error(`[RIVERINE] ApiService ${error}`)
    })
  },

  getWithParams (resource, params ) {
    return Vue.axios.get(`${resource}`, { params: params } )
  },

  post (resource, params) {
    return Vue.axios.post(`${resource}/`, params)
  },

  getPublicApi ( resource ) {
    const config = {
      headers:{
         "Content-Type": "application/json",
         "Authorization": "",
      }
    };
    return Vue.axios.get(`${resource}`,config).catch(error => {
      console.log(error)
      throw new Error(`[RIVERINE] ApiService ${error}`)
    })
  },
  API_URL: API_URL,
}

export default ApiService
