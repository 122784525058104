import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'
import jwt_decode from 'jwt-decode'
import createPersistedState from 'vuex-persistedstate'
import router from '@/router'
import JwtService from '@/common/jwt.service'
import ApiService from '@/common/api.service'
import forms from './forms.module'
import { GET_REPORT, FETCH_SENSORS, GET_HEIGHT_DATA, GET_SOCAVATION_DATA, FETCH_PDF_REPORTS } from './actions.type'
import { SET_SENSORS, SET_HEIGHT_DATA, SET_SOCAVATION_DATA, SET_SNACKBAR, SET_PDF_REPORTS } from './mutations.type'

Vue.use(Vuex)
Vue.use(VueAxios, axios)

Vue.axios.defaults.baseURL = process.env.VUE_APP_HOST
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'
Vue.axios.defaults.headers.common.Authorization = `JWT ${localStorage.getItem('token')}`

export default new Vuex.Store({
  plugins: [createPersistedState({
	  storage: window.sessionStorage,
  })],
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    drawer: null,

    jwt: localStorage.getItem('token'),
    user: JSON.parse(localStorage.getItem('auth')),
    isAuthenticated: localStorage.getItem('isAuthenticated'),

	notifications: [],
	non_readed_notifs: [],
	non_readed_notifs_cant: 0,

	puentes: [],
	markers: [],
	reports:[],
	tooltipPuenteActivo: {},
	selectedSensors: [],
	socavationData: {
		time: [],
		records: []
	},
	heightData: {
		time: [],
		records: []
	},
	loadingSocavationData: false,
	loadingHeightData: false,
	loadingReport: false,
	showSnackBar: false
  },
  getters: {
	bridgeList(state){
		return state.puentes
	},
	markerList(state){
		return state.markers
	},
	socavationData(state){
		const pattern = /(\d{2})\-(\d{2})\-(\d{4}) (\d{2})\:(\d{2})\:(\d{2})/;
		return [{
			type:"scatter",
			mode: 'markers',
			name: 'Socavación',
			x: state.socavationData.time.map(d => new Date(d.replace(pattern,'$3-$2-$1 $4:$5:$6'))),
			y: state.socavationData.records
		},
		{
			type:"scatter",
			mode: 'lines',
			name: 'Socavación Crítica Alta',
			line: {
				color: 'red',
				width: 3
			},
			x: state.socavationData.time.map(d => new Date(d.replace(pattern,'$3-$2-$1 $4:$5:$6'))),
			y: state.socavationData.time.map(d => 5.2),
			visible:'legendonly'
		},
		{
			type:"scatter",
			mode: 'lines',
			name: 'Socavación Crítica Media',
			line: {
				color: 'orange',
				width: 3
			},
			x: state.socavationData.time.map(d => new Date(d.replace(pattern,'$3-$2-$1 $4:$5:$6'))),
			y: state.socavationData.time.map(d => 3.1),
			visible:'legendonly'
		}]
	},
	heightData(state){
		const pattern = /(\d{2})\-(\d{2})\-(\d{4}) (\d{2})\:(\d{2})\:(\d{2})/;
		return [{
			type:"scatter",
			mode: 'markers',
			name: 'Cota Lecho',
			x: state.heightData.time.map(d => new Date(d.replace(pattern,'$3-$2-$1 $4:$5:$6'))),
			y: state.heightData.records
		},
		{
			type:"scatter",
			mode: 'lines',
			name: 'Cota Fundación',
			line: {
				color: 'gray',
				width: 3
			},
			x: state.heightData.time.map(d => new Date(d.replace(pattern,'$3-$2-$1 $4:$5:$6'))),
			y: state.heightData.time.map(d => 150.1),
			visible:'legendonly'
		},
		{
			type:"scatter",
			mode: 'lines',
			name: 'Cota Crítica Alta',
			line: {
				color: 'red',
				width: 3
			},
			x: state.heightData.time.map(d => new Date(d.replace(pattern,'$3-$2-$1 $4:$5:$6'))),
			y: state.heightData.time.map(d => 154),
			visible:'legendonly'
		},
		{
			type:"scatter",
			mode: 'lines',
			name: 'Cota Crítica Media',
			line: {
				color: 'orange',
				width: 3
			},
			x: state.heightData.time.map(d => new Date(d.replace(pattern,'$3-$2-$1 $4:$5:$6'))),
			y: state.heightData.time.map(d => 156.1),
			visible:'legendonly'
		}]
	}
  },
  mutations: {
	[SET_PDF_REPORTS] (state, payload) {
		state.reports = payload
	},
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
	updatePuenteStatus (state, payload) {
		console.log(payload);
		console.log(state.puentes);
		console.log('SE ACTUALIZA ESTADO DE PUENTE')
		for(var i = 0; i < state.puentes.length; i++){
			if (state.puentes[i].id == payload.id){
				//Ahora debemos obtener todos los last_values para cada sensor respectivo
				var status = 4;
				var status_candidates = [];
				for(var j = 0; j < state.puentes[i].sensores.length; j++){
					//Verificamos que sensor haya entregado datos en los últimos 15 minutos
					var time_diff = Date.now() - state.puentes[i].sensores[j].last_value_dt;
					if(time_diff < 15 * 60 * 1000){
						var this_value = state.puentes[i].sensores[j].last_value
						if(this_value){
							var media = state.puentes[i].sensores[j].cota_critica_media;
							var alta = state.puentes[i].sensores[j].cota_critica_alta;
							if (this_value > media){
								status_candidates.push(1);
							}
							if (this_value <= media && this_value > alta){
								status_candidates.push(2);
							}
							if (this_value <= alta){
								status_candidates.push(3);
							}
						}
					}
				}
				if(status_candidates.length > 0){
					status = Math.max(...status_candidates);
				}
				console.log(status_candidates);
				console.log(status);
				state.puentes[i].status = status;
				state.markers[i].status = status;
			}
		}
		console.log(state.markers);
	},
	updateSensorLastValue (state, payload) {
		console.log('SE ACTUALIZA LAST VALUE DEL SENSOR')
		console.log(state.puentes);
		console.log(payload.idpuente);
		console.log(payload.idsensor);
		console.log(payload.last_value);
		for(var i = 0; i < state.puentes.length; i++){
			if (state.puentes[i].id == payload.idpuente){
				for(var j = 0; j < state.puentes[i].sensores.length; j++){
					if(state.puentes[i].sensores[j].id == payload.idsensor){
						state.puentes[i].sensores[j].last_value = payload.last_value;
						state.puentes[i].sensores[j].last_value_dt = Date.now();
					}
				}
			}
		}
		console.log(state.markers);
	},
	updateAlerts (state, payload) {
		console.log('SE ACTUALIZAN ALERTAS');
		state.notifications.push(payload);
		state.non_readed_notifs.push(payload);
	},
	markNotification (state, payload) {
		console.log('MARK NOTIF FROM STORE');
		var data = {}
		data.alert_id = payload.id_notif;

		Vue.axios.post('notificationreaded/', data).then((response) => {
			
			console.log(response);
			if (response.status == 200){

				var i = state.notifications.findIndex(element => element.deviceId === payload.deviceId && element.measurementTime === payload.measurementTime);
				var j = state.non_readed_notifs.findIndex(element => element.deviceId === payload.deviceId && element.measurementTime === payload.measurementTime);
				
				state.notifications[i].read_status = true;
				state.non_readed_notifs.splice(state.non_readed_notifs[j], 1);
			}
			//commit('setNotificationsUser', response.data.token);
		})
	},
    setAuthUser (state, payload) {
		localStorage.setItem('auth', JSON.stringify(payload.authUser))
		state.user = payload.authUser
		localStorage.setItem('isAuthenticated', payload.isAuthenticated)
		state.isAuthenticated = payload.isAuthenticated
	},
	setNotificationsUser (state, payload) {
		state.notifications = payload
		state.non_readed_notifs = [];
		for(var i = 0; i < state.notifications.length; i++){
			if(state.notifications[i].read_status == false){
				state.non_readed_notifs.push(state.notifications[i]);
			}
		}

		state.non_readed_notifs = state.non_readed_notifs.sort(function(a,b){

			var tA = a.measurementTime.split(' ');
			var partesFechaA = tA[0].split('-');
			var fechaA = partesFechaA[2].concat("-", partesFechaA[1], "-", partesFechaA[0]);
			var fechaHoraA = fechaA.concat("T", tA[1]);

			var tB = b.measurementTime.split(' ');
			var partesFechaB = tB[0].split('-');
			var fechaB = partesFechaB[2].concat("-", partesFechaB[1], "-", partesFechaB[0]);
			var fechaHoraB = fechaB.concat("T", tB[1]);

			var dateA = new Date(fechaHoraA).getTime();
			var dateB = new Date(fechaHoraB).getTime();
			var response = false;
			if (dateA < dateB){
				response = 1;
			}
			else{
				if(dateA > dateB){
					response = -1;
				}
				else{
					response = 0;
				}
			}
			return response;
		  });
		console.log(state.non_readed_notifs);

	},
	setUserEmpresa (state, payload) {
		state.user.empresa = payload
	},
    setIsModerator (state, payload) {
        state.isModerator = payload 
    },
	setPuentesList (state, payload) {
		state.puentes = payload
	},
	setMarkersList (state, payload) {
		state.markers = payload
	},
	setTooltipPuente (state, payload) {
		state.tooltipPuente = payload
	},

	updateToken (state, newToken) {
		localStorage.setItem('token', newToken)
		state.jwt = newToken
		Vue.axios.defaults.headers.common.Authorization = `JWT ${newToken}`
	},

	removeToken (state) {
		localStorage.removeItem('token')
		localStorage.removeItem('auth')
		localStorage.removeItem('isAuthenticated')
		state.isAuthenticated = false
		state.jwt = null
		Vue.axios.defaults.headers.common.Authorization = null
	},

	purgeAuth (state) {
		localStorage.clear()
		state.isAuthenticated = false
		state.jwt = null
		state.user = {}
		router.push({ name: 'LoginView' }).catch(() => {})
	},

	[SET_SENSORS] (state, payload) {
		state.selectedSensors = payload.sensors
	},

	[SET_SOCAVATION_DATA] (state, payload) {
		state.socavationData = payload
	},

	[SET_HEIGHT_DATA] (state, payload) {
		state.heightData = payload
	},

	[SET_SNACKBAR] (state, payload) {
		state.showSnackBar = payload
	}

  },
  actions: {
    refreshToken ({ commit }) {
		const payload = {
			token: this.state.jwt,
		}
		Vue.axios.post('auth/refresh_token/', payload).then((response) => {
			commit('updateToken', response.data.token)
		})
		.catch((error) => {
			commit('removeToken')
			throw new Error(`API ${error}`)
		})
	},

	inspectToken ({ dispatch, commit }) {
		const token = this.state.jwt
		if (token) {
			const decoded = jwt_decode(token)
			const exp = decoded.exp
			const orig_iat = decoded.orig_iat

			const max = 628200
			const min = 60
			const time = 60 * 60
			const diff = exp - Date.now() / 1000
			const life = Date.now() / 1000 - orig_iat

			if (diff > min && diff < time && life < max) {
				dispatch('refreshToken')
			} else if (diff < min || life > max) {
				commit('removeToken')
			}
		}
	},

	logout ({ commit }) {
		commit('purgeAuth')
	},

	checkAuth ({ commit }) {
		console.log('CHECK AUTH')
		if (JwtService.getToken()) {
			ApiService.setHeader()
			ApiService.get('currentuser')
			  .then(({ data }) => {
				commit('setAuthUser', { authUser: data, isAuthenticated: true })
			  })
			  .catch(({ response }) => {
				console.log(response)
				commit('purgeAuth')
			  })
		  } else {
			commit('purgeAuth')
		  }
	},

	[GET_REPORT](context, payload) {
		return new Promise((resolve) => {
			this.state.loadingReport = true
			ApiService.simpleGet('getreport/' + payload.id + '?start_date=' + payload.startDate + '&end_date=' + payload.endDate)
			.then((response) => {
				if(response.status == 200){
					const filename = payload.id + '.csv'
					const fileURL = window.URL.createObjectURL(new Blob([response.data]))
					const fileLink = document.createElement('a')
					fileLink.href = fileURL
					fileLink.setAttribute('download', filename)
					document.body.appendChild(fileLink)
					fileLink.click()
					resolve(response.data)
				} else if (response.status == 204){
					console.log("No hay datos disponibles para estas fechas")
					context.commit(SET_SNACKBAR, true)
				}
				this.state.loadingReport = false
			})
			.catch((response) => {
				console.log(response)
				this.state.loadingReport = false
			})
		})
	},

	[FETCH_SENSORS](context, payload) {
		return new Promise((resolve) => {
			ApiService.get('puentes/' + payload.toString() + '/sensors')
			.then((response) => {
				context.commit(SET_SENSORS, response.data)
				resolve(response.data)
			})
			.catch(({ response }) => {
				console.log(response)
			})
		})
	},

	[GET_SOCAVATION_DATA](context, payload) {
		return new Promise((resolve) => {
			this.state.loadingSocavationData = true
			ApiService.simpleGet('gettimeseries/' + payload+ '?type=socavacion')
			.then((response) => {
				if(response.status == 200){
					context.commit(SET_SOCAVATION_DATA, {
						"time": JSON.parse(response.data.time),
						"records": JSON.parse(response.data.records)
					})
				} else if (response.status == 204) {
					console.log("No hay datos disponibles para este periodo de tiempo")
					context.commit(SET_SOCAVATION_DATA, {
						"time": [],
						"records": []
					})
				}
				resolve(response.data)
				this.state.loadingSocavationData = false
			})
			.catch((response) => {
				console.log(response)
				this.state.loadingSocavationData = false
			})
		})
	},
	[GET_HEIGHT_DATA](context, payload) {
		return new Promise((resolve) => {
			this.state.loadingHeightData = true
			ApiService.simpleGet('gettimeseries/' + payload + '?type=cota')
			.then((response) => {
				if(response.status == 200){
					context.commit(SET_HEIGHT_DATA, {
						"time": JSON.parse(response.data.time),
						"records": JSON.parse(response.data.records)
					})
				} else if (response.status == 204) {
					console.log("No hay datos disponibles para este periodo de tiempo")
					context.commit(SET_HEIGHT_DATA, {
						"time": [],
						"records": []
					})
				}
				resolve(response.data)
				this.state.loadingHeightData = false
			})
			.catch((response) => {
				console.log(response)
				this.state.loadingHeightData = false
			})
		})
	},

	[FETCH_PDF_REPORTS](context, payload) {
		return new Promise((resolve) => {
			ApiService.simpleGet('pdf_reports')
			.then((response) => {
				context.commit(SET_PDF_REPORTS, response.data)
				resolve(response.data)
			})
			.catch(({ response }) => {
				console.log(response)
			})
		})
	},



  },
  modules: {
	  forms,
  },
})
