export const SET_BEDS = 'setBeds'
export const SET_MORPHOLOGIES = 'setMorphologies'
export const SET_REGIONS = 'setRegions'
export const SET_RIVERS = 'setRivers'
export const SET_TENANTS = 'setTenants'
export const SHOW_SNACKBAR = 'showSnackbar'
export const SET_SENSORS = 'setSensors'
export const SET_SOCAVATION_DATA = 'setSocavationData'
export const SET_HEIGHT_DATA = 'setHeightData'
export const SET_SNACKBAR = 'setSnackbar'
export const SET_PDF_REPORTS = 'setPdfReports'
