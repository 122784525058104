import {
    BRIDGE_CREATE,
    FETCH_BEDS,
    FETCH_MORPHOLOGIES,
    FETCH_REGIONS,
    FETCH_RIVERS,
    FETCH_TENANTS,
    RIVER_CREATE,
    SENSOR_CREATE,
    TENANT_CREATE,
} from './actions.type'

import {
    SET_BEDS,
    SET_MORPHOLOGIES,
    SET_REGIONS,
    SET_RIVERS,
    SET_TENANTS,
    SHOW_SNACKBAR,
} from './mutations.type'

import ApiService from '@/common/api.service'

const initialState = {
    showSnackbar: {
        show: false,
        color: 'success',
        msg: '',
    },
    regions: [],
    beds: [],
    morphologies: [],
    tenants: [],
}
const state = { ...initialState }
const actions = {
    [TENANT_CREATE] ({ commit }, payload) {
        return ApiService.post('companies', { empresa: payload })
    },
    [BRIDGE_CREATE] ({ commit }, payload) {
        return ApiService.post('bridges', { puente: payload })
    },
    [SENSOR_CREATE] ({ commit }, payload) {
        console.log(payload)
        return ApiService.post('sensors', { sensor: payload })
    },
    [FETCH_BEDS] ({ commit }) {
        return ApiService.get('beds').then(({ data }) => {
            commit(SET_BEDS, data.lechos)
        })
    },
    [FETCH_MORPHOLOGIES] ({ commit }) {
        return ApiService.get('morphologies').then(({ data }) => {
            commit(SET_MORPHOLOGIES, data.morfologias)
        })
    },
    [FETCH_REGIONS] ({ commit }) {
        return ApiService.get('regions').then(({ data }) => {
            commit(SET_REGIONS, data.regiones)
        })
    },
    [FETCH_RIVERS] ({ commit }) {
        return ApiService.get('rivers').then(({ data }) => {
            commit(SET_RIVERS, data.rios)
        })
    },
    [FETCH_TENANTS] ({ commit }) {
        return ApiService.get('companies').then(({ data }) => {
            commit(SET_TENANTS, data.empresas)
        })
    },
    [RIVER_CREATE] ({ commit }, payload) {
        return ApiService.post('rivers', { rio: payload })
    },
}
const mutations = {
    [SHOW_SNACKBAR] (state, payload) {
        state.showSnackbar = payload
    },
    [SET_BEDS] (state, payload) {
        state.beds = payload
    },
    [SET_MORPHOLOGIES] (state, payload) {
        state.morphologies = payload
    },
    [SET_REGIONS] (state, payload) {
        state.regions = payload
    },
    [SET_RIVERS] (state, payload) {
        state.rivers = payload
    },
    [SET_TENANTS] (state, payload) {
        state.tenants = payload
    },
}

const getters = {
    showSnackbar (state) {
        return state.showSnackbar
    },
    beds (state) {
        return state.beds
    },
    morphologies (state) {
        return state.morphologies
    },
    regions (state) {
        return state.regions
    },
    rivers (state) {
        return state.rivers
    },
    tenants (state) {
        return state.tenants
    },
}

export default {
    state,
    actions,
    mutations,
    getters,
}
