<template>
  <router-view />
</template>

<script>
  export default {
    name: 'App',
  }
</script>

<style lang="sass">
.main-content
  .v-alert
    background-color: transparent !important
  .v-alert__wrapper
    display: inherit
.v-main
  overflow-x: hidden
.v-main__wrap
  overflow: hidden
</style>
