export const BRIDGE_CREATE = 'createBridge'
export const FETCH_BEDS = 'fetchBeds'
export const GET_REPORT = 'getReport'
export const FETCH_MORPHOLOGIES = 'fetchMorphologies'
export const FETCH_REGIONS = 'fetchRegions'
export const FETCH_RIVERS = 'fetchRivers'
export const FETCH_TENANTS = 'fetchTenants'
export const RIVER_CREATE = 'createRiver'
export const SENSOR_CREATE = 'createSensor'
export const TENANT_CREATE = 'createTenant'
export const FETCH_SENSORS = 'fetchSensors'
export const GET_SOCAVATION_DATA = 'getSocavationData'
export const GET_HEIGHT_DATA = 'getHeightData'
export const FETCH_PDF_REPORTS = 'fetchPdfReports'