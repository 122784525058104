import { render, staticRenderFns } from "./MaterialAlert.vue?vue&type=template&id=089e9423&"
import script from "./MaterialAlert.vue?vue&type=script&lang=js&"
export * from "./MaterialAlert.vue?vue&type=script&lang=js&"
import style0 from "./MaterialAlert.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VAlert,VBtn,VIcon})
